import React from 'react';
import styled, { css } from 'styled-components';
import { handleOnElementDrag, handleOnDragEnd } from './utils/modulePositioning';

const StyledSection = styled.section`
  ${({ draggable }) => draggable === 'true' && css`
    overflow: hidden;
    max-height: 10rem;
  `}
`;

const SubSection = props => (
  <StyledSection
    draggable={props.isDraggable}
    onDragStart={e => handleOnElementDrag(e, props, props.reference)}
    onDragEnd={handleOnDragEnd}
    {...props}
  >
    {props.children}
  </StyledSection>
);

export default SubSection;
