const templateSetDeleted = (e, props) => {
  const id = props.formId !== undefined ? props.formId : e.target.formId;

  if (!props.modules[id].deleted) {
    props.moduleDeleted({ id, deleted: true , formId: props.formId});
  }

  props.updateContentEdited({
    id: props.modules[id].content,
    edited: true
  });

  props.globalEdited(true);
};

export default templateSetDeleted;
