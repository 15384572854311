import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';

import { globalEdited } from '../../../store/global/action';
import { moduleEdited, changePosition } from '../../../store/modules/action';

// utils
import {
  isDraggable,
  handleOnModuleDrag,
  handleOnDragEnd,
  moveModuleUp,
  moveModuleDown
} from './utils/modulePositioning'

const DragButton = styled.span`
  background-color: ${props => props.theme.color.brandNeutral};
  border: 1px solid ${props => props.theme.color.brand};
  bottom: calc(100% - 3.5rem);
  color: ${props => props.theme.color.brand};
  padding: .5rem 1.5rem;
  position: absolute;

  &[disabled] {
    opacity: 0.3;
    cursor: not-allowed;
  }
`;

const FieldSet = styled.fieldset`
  ${({ modulesCollapsed }) => modulesCollapsed && css`
    max-height: 15rem;
    overflow: hidden;

    ${DragButton} {
      bottom: calc(100% - 4.5rem);
    }
  `}
`;


const DragHandle = styled(DragButton)`
  cursor: all-scroll;
  left: calc(100% - 5rem);
`;

const ArrowUp = styled(DragButton)`
  cursor: n-resize;
  left: calc(100% - 10rem);
`;

const ArrowDown = styled(DragButton)`
  cursor: s-resize;
  left: calc(100% - 15rem);
`;

const Section = props => (
  isDraggable(props)
    ? (
      <FieldSet {...props}>
        <DragHandle draggable="true"
          onDragStart={e => handleOnModuleDrag(e, props)}
          onDragEnd={handleOnDragEnd}
          data-test-id={`drag-${props.formId}`}
        >
          ↕
        </DragHandle>

        <ArrowUp data-direction="up"
          title="move module up one space"
          onClick={() => moveModuleUp(props)}
          disabled={props.modules[props.formId]?.position <= 1}
          data-test-id={`arrow-up-${props.formId}`}
        >
          ↑
        </ArrowUp>

        <ArrowDown
          data-direction="down"
          title="move module down one space"
          onClick={() => moveModuleDown(props)}
          disabled={props.modules[props.formId]?.position >= Object.values(props.modules).length}
          data-test-id={`arrow-down-${props.formId}`}
        >
          ↓
        </ArrowDown>

        { props.children }
      </FieldSet>
    ) : <FieldSet draggable="false" {...props}>{props.children}</FieldSet>
)

Section.propTypes = {
  changePosition: PropTypes.func.isRequired,
  children: PropTypes.any.isRequired,
  globalEdited: PropTypes.func.isRequired,
  moduleEdited: PropTypes.func.isRequired,
  modulesCollapsed: PropTypes.bool.isRequired
};

const matchStateToProps = (state) => ({
  modulesCollapsed: state.global.modulesCollapsed
});

const mapDispatchToProps = dispatch => ({
  changePosition: val => dispatch(changePosition(val)),
  globalEdited: val => dispatch(globalEdited(val)),
  moduleEdited: val => dispatch(moduleEdited(val)),
});

export default connect(matchStateToProps, mapDispatchToProps)(Section);
