import React, { Fragment } from 'react';
import styled from 'styled-components';
import { handleNestedDrop } from './utils/modulePositioning';

const ContentZone = styled.div`
  display: block;
  padding: 1rem 0;
`;

const ContentWrapper = styled.div``;

const ContentBody = props => (
  props.isDraggable
    ? (
      <Fragment>
        <ContentZone
          onDragOver={e => e.preventDefault()}
          onDrop={e => handleNestedDrop(e, props)}
        />
        <ContentWrapper
          onDragOver={e => e.preventDefault()}
          onDrop={e => handleNestedDrop(e, props)}
          {...props}
        >
          {props.children}
        </ContentWrapper>
        <ContentZone
          onDragOver={e => e.preventDefault()}
          onDrop={e => handleNestedDrop(e, props)}
        />
      </Fragment>
    )
    : (
      <Fragment>
        <ContentZone />
        <ContentWrapper {...props}>
          {props.children}
        </ContentWrapper>
        <ContentZone />
      </Fragment>
    )
);

export default ContentBody;
